import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _waitList$: BehaviorSubject<any>;
  private _errorInfo$: BehaviorSubject<string>;

  // private url = 'http://localhost:3001/';
  private url = 'https://api.p6api.de/';
  private socket: any;

  get waitList$(): Observable<any> {
    return this._waitList$.asObservable();
  }

  get errorInfo$(): Observable<any> {
    return this._errorInfo$.asObservable();
  }

  constructor(private router: Router) {
    this._waitList$ = new BehaviorSubject<any>(null);
    this._errorInfo$ = new BehaviorSubject<any>(null);
    // this.initSocket('testc');
  }

  public checkSocket(namespace): void {
    this.socket = io(this.url + namespace);
    if (!this.socket.connected) {
      this.initSocket(namespace);
    }
  }

  public removeId(idObj): void {
    this.socket.emit('askForRemove', idObj);
  }

  public initSocket(namespace): void {
    this.socket = io(this.url + namespace);

    this.socket.on('connect', data => {
      this.router.navigate(['/tabs/' + namespace + '/tab1/']);
    });

    this.socket.on('error', error => {
      console.log('error', error);
      this._errorInfo$.next(error);
      this.router.navigate(['/login/error']);
    });

    this.socket.on('getIds', data => {
      console.log('data', data);

      const newData = data ? data : [];
      this._waitList$.next(newData);
    });
  }
}
