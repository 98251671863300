import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from '../services/socket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  info: string;
  code = ''; // HLAU1B

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private socketService: SocketService,
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.info = params.info ? 'Bitte überprüfen sie Ihren Code' : null;
    });
  }

  onLogin() {
    if (this.code && this.code !== '') {
      this.socketService.initSocket(this.code);
    } else {
      this.info = 'Bitte code eingeben';
    }
  }

  ngOnInit() {}
}
